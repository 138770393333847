import './CardProducto.css'
import nologo from '../../imgs/no-logo.png'
import { useEffect, useState } from 'react'
import { numberFormat } from '../../js/util'

const CardProducto = ({
    producto = {},
    modoNormal = true,
    onClick = () => { },
    permitirEditar = true,
}) => {
    const [precio, fijaPrecio] = useState(null)

    useEffect(() => {
        if (producto?.eureka_st_productos_precios?.length > 0) {
            fijaPrecio(producto.eureka_st_productos_precios[0])
        }
        else {
            fijaPrecio({})
        }
    }, [producto?.eureka_st_productos_precios, fijaPrecio])

    return (
        <div className={`card-producto ${modoNormal ? '' : 'card-producto--inactivo'}`} onClick={onClick}>
            <img className="card-producto__foto" src={nologo} alt="logo" />
            <div className="card-producto__informacion">
                <p className="card-producto__informacion__precio">{numberFormat(producto?.precio)}</p>
                <p className="card-producto__informacion__nombre">{producto?.nombre ?? 'Sin nombre'}</p>
                {producto.inventario ? <p className="card-producto__informacion__ineventario">En inventario: {producto?.inventario ?? 0}</p> : undefined}
            </div>
        </div>
    )
}

export default CardProducto