import { useEffect, useState } from 'react'
import { Formulario, Input, Select, ModalForm, LoaderCircle, InputSearch, Miniatura, Button } from 'eureka-design'
import { useSearchPacienteQuery } from '../../services/paciente'
import { adaptarDatos } from '../../js/selector'
import { tiempos } from '../../js/receta'
import { useCrearRecetaMutation, useCrearConceptoRecetaMutation, useCrearPeriodoRecetaMutation, useCrearDetalleRecetaMutation } from '../../services/receta'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { adaptarFechaBD } from '../../js/util'
import { useSearchMedicamentoQuery } from '../../services/medicina'
import SeccionFormMedicamento from '../../components/Receta/SeccionFormMedicamento/SeccionFormMedicamento'
import ListaMedicamentos from '../../components/Receta/ListaMedicamentos/ListaMedicamentos'
import { obtenerFoto } from '../../js/multimedia'

export const NOM_FORM_RECETA = 'receta'

const FormReceta = ({ UpProps, UpTittle, ShowAlert, titulo, receta, paciente = null }) => {
    const [SearchPaciente, fijaSearchPaciente] = useState('')
    const [SearchMedicina, fijaSearchMedicina] = useState('')
    const [MedicamentosLista, fijaMedicamentos] = useState([])
    const [MedicamentoDetalle, fijaMedicamentoDetalle] = useState(null)
    const { data: medicamentos, isLoading: cargandoMedicamentos } = useSearchMedicamentoQuery({ search: SearchMedicina })
    const { data: pacientes, isLoading: loadingPacientes } = useSearchPacienteQuery({ search: SearchPaciente })

    const [crearReceta, { isLoading: lReceta, isError: eReceta, isSuccess: sReceta }] = useCrearRecetaMutation()
    const [crearConceptoReceta, { isLoading: lConceptoReceta, isError: eConceptoReceta, isSuccess: sConceptoReceta }] = useCrearConceptoRecetaMutation()
    const [crearPeriodoReceta, { isLoading: lPeriodoReceta, isError: ePeriodoReceta, isSuccess: sPeriodoReceta }] = useCrearPeriodoRecetaMutation()
    const [crearDetalleReceta, { isLoading: lDetalleReceta, isError: eDetalleReceta, isSuccess: sDetalleReceta }] = useCrearDetalleRecetaMutation()

    const [doctor] = useState(infoUsuario())
    const [recetaId, fijaRecetaId] = useState(receta?.id)
    const [mostrarModal, fijaMostrarModal] = useState(false)
    const [totalReceta, fijaTotalRecet] = useState(0)
    const [detalleMedicamento, fijaDetalleMedicamento] = useState(null)
    const [medicamentoSeleccionado, fijaMedicamentoSeleccionado] = useState(null)

    useEffect(() => {
        UpTittle(titulo)
    })

    useEffect(() => {
        if (totalReceta > 0) {
            handlerInput(totalReceta, "total")
        }

    }, [totalReceta])

    const handlerCrearReceta = (nom, val) => {
        crearReceta({
            id: recetaId ?? receta?.id,
            body: {
                [nom]: val,
                vendedor: doctor?.id,
                fecha: receta?.fecha ?? adaptarFechaBD(),
                cxp_cxc: 'cobrar',
                total: totalReceta,
            }
        })
            .unwrap()
            .then(res => {
                if (res?.mensaje?.id) {
                    let id = res.mensaje.id
                    fijaRecetaId(id)
                    fijaDetalleMedicamento({
                        ...detalleMedicamento,
                        transaccion: id
                    })
                    return (id)
                }
                else return recetaId ?? receta?.id
            })
    }

    const handlerCrearDetalleReceta = (nom, val) => {
        crearDetalleReceta({
            id: MedicamentoDetalle ?? null,
            body: {
                [nom]: val,
                nombre: "descripcion",
                tipo: "receta",
                transaccion: recetaId,

            }
        }).unwrap()
            .then(res => {
                if (res?.mensaje?.id) {
                    let id = res.mensaje.id
                    fijaMedicamentoDetalle(id)
                    return (id)
                }
            })
    }


    const handlerAgregarMedicamento = () => {
        const concepto = detalleMedicamento;
        if (!concepto?.cantidad_pastillas || !concepto?.horas || !concepto?.cantidad_periodo || !concepto?.periodo || !concepto?.horas) {
            ShowAlert({ titulo: "Espera...", mensaje: 'Debes completar todos los campos de periodo y cantidad del concepto', icono: 'warning', time: 4 })
        }
        else if (!concepto?.producto) {
            ShowAlert({ titulo: "Espera...", mensaje: 'Debes seleccionar un medicamento', icono: 'warning', time: 4 })
        } else if (!concepto?.transaccion) {
            ShowAlert({ titulo: "Espera...", mensaje: 'Debes seleccionar un paciente', icono: 'warning', time: 4 })
        }
        else {

            fijaMedicamentos([...MedicamentosLista, concepto.producto])
            crearPeriodoReceta({
                id: null,
                body: {
                    nombre: concepto?.periodo,
                    tipo: "receta",
                    total: concepto?.cantidad_periodo,
                    hora: `${concepto?.horas.length < 1 ? "0" + concepto.horas : concepto.horas}:00`,
                    cantidad: concepto?.cantidad_pastillas,
                }
            }).unwrap()
                .then(res => {
                    if (res?.mensaje?.id) {
                        fijaDetalleMedicamento(null)
                        crearConceptoReceta({
                            id: null,
                            body: {
                                transaccion: concepto?.transaccion,
                                producto: concepto?.producto?.id,
                                cantidad: concepto?.cantidad_pastillas,
                                costo: concepto?.producto?.eureka_st_productos_precios[0]?.precio,
                                periodo: res.mensaje.id,
                            }
                        })
                    }

                })
        }

    }

    const handlerInput = (val, nom) => {
        handlerCrearReceta(nom, val)
        handlerProps(nom, val)
    }

    const handlerUsuarios = () => {
        let paciente = pacientes?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/nouser.jpg"}
                        src={obtenerFoto(item.foto, "mp_pacientes")}
                    />
                }
            }
        });
        return paciente;
    }

    const handlerMedicamentos = () => {
        let medicamento = medicamentos?.map((item, index) => {
            return {
                value: item,
                text: item.codigo,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/nouser.jpg"}
                        src={obtenerFoto(item.foto, "mp_medicamentos", false)}
                    />
                }
            }
        });
        return medicamento;
    }

    const handlerProps = (nom, val, extra) => {
        UpProps({
            [NOM_FORM_RECETA]: {
                ...receta,
                id: recetaId ?? receta?.id,
                ...extra,
                [nom]: val
            }
        })
    }

    const handlerOnChangeMedicamento = (nom, val, tipo) => {

        let extra = null
        if (tipo === 'select') {
            extra = { [`${nom}_texto`]: val.text }
            val = val.id
        } else if (tipo === 'search') {
            if (nom === 'producto') {
                fijaTotalRecet(totalReceta + val.eureka_st_productos_precios ? val.eureka_st_productos_precios[0].precio : 0)  // Se suma el precio del medicamento al total de la receta 
            }
            extra = { [`${nom}_texto`]: val }
        }
        else {
            extra = {}
        }

        fijaDetalleMedicamento({
            ...detalleMedicamento,
            ...extra,
            [nom]: val,
            transaccion: recetaId,
        })
    }

    if (loadingPacientes || cargandoMedicamentos) return
    else {
        return (
            <>
                <ModalForm
                    show={mostrarModal}
                    titulo="Generando receta"
                    onClose={() => { fijaMostrarModal(false) }}
                >
                    <LoaderCircle size="medium" />
                </ModalForm>

                <Formulario
                    titulo="Agregar la información de la receta"
                    botones={[
                        // { iconLeft: 'fa-plus', texto: 'Agregar', onClick: handlerAgregarMedicamento },
                        // { texto: 'Generar receta', onClick: () => { fijaMostrarModal(true) } },
                    ]}
                    isLoading={[lReceta, lConceptoReceta, lPeriodoReceta]}
                    isError={[eReceta, eConceptoReceta, ePeriodoReceta]}
                    isSuccess={[sReceta, sConceptoReceta, sPeriodoReceta]}
                >

                    {(!paciente) ? <InputSearch
                        nombre="paciente"
                        placeholder="Busca un paciente por su nombre..."
                        isLoading={loadingPacientes}
                        iconLeft="fa-search"
                        opciones={pacientes ? handlerUsuarios() : []}
                        changeFunction={(value) => {
                            fijaSearchPaciente(value)
                        }}
                        OpcionSelect={(item) => {
                            handlerInput(item.id, "cliente")
                        }}
                    /> : undefined}
                    <Input
                        type="textarea"
                        placeholder="Diagnóstico"
                        nombre="texto"
                        changeFunction={(value, nombre) => {
                            if (recetaId || receta?.id) {
                                handlerCrearDetalleReceta(nombre, value)
                            } else {

                                ShowAlert({ titulo: "Espera...", mensaje: 'Debes seleccionar un paciente', icono: 'warning', time: 4 })
                            }
                        }}
                        isLoading={lDetalleReceta}
                        value={receta?.tipo}
                    />
                    {
                        (paciente) ?
                            <Select
                                value={`${paciente?.eureka_st_transacciones?.length} receta(s) emitida(s) anteriormente`}
                                disabled={true}
                            /> : undefined
                    }

                    <br />
                    <p><b>Medicamentos</b></p>
                    {/* <SeccionFormMedicamento
                        medicamentos={medicamentos}
                        onChange={handlerOnChangeMedicamento}
                        detalleMedicamento={detalleMedicamento}
                    /> */}
                    <div className="seccion-principal">
                        <div className='seccion-principal__header'>
                            <div className="seccion-principal__input-principal">
                                <InputSearch
                                    nombre="medicamento"
                                    placeholder="Busca un medicamento..."
                                    isLoading={cargandoMedicamentos}
                                    iconLeft="fa-search"
                                    opciones={medicamentos ? handlerMedicamentos() : []}
                                    changeFunction={(value) => {
                                        fijaSearchMedicina(value)
                                    }}
                                    OpcionSelect={(item) => {
                                        handlerOnChangeMedicamento('producto', item, 'search')
                                    }}
                                />
                            </div>

                        </div>
                        <div className='seccion-principal__header'>
                            <div className="seccion-principal__input-secundario">
                                <Select
                                    placeholder="Periodo de tiempo"
                                    nombre="periodo"
                                    options={adaptarDatos(tiempos, 'texto', 'texto')}
                                    value={detalleMedicamento?.periodo ?? ''}
                                    changeFunction={(item, name) => {
                                        handlerOnChangeMedicamento(name, item, 'select')
                                    }}
                                />
                            </div>
                            <div className="seccion-principal__input">

                                <div className="seccion-principal__input__input" >
                                    <Input
                                        type="number"
                                        nombre="cantidad_periodo"
                                        max={99}
                                        placeholder="Cantidad del periodo"
                                        value={detalleMedicamento?.cantidad_periodo ?? '00'}
                                        changeFunction={(value, nombre) => {
                                            handlerOnChangeMedicamento(nombre, value, 'input')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='seccion-principal__body'>
                            <div className="seccion-principal__input">
                                <div className="seccion-principal__input__input" >
                                    <Input
                                        type="number"
                                        nombre="cantidad_pastillas"
                                        max={99}
                                        placeholder="Cantidad de pastillas 0"
                                        value={detalleMedicamento?.cantidad_pastillas ?? '00'}
                                        changeFunction={
                                            (value, nombre) => {
                                                handlerOnChangeMedicamento(nombre, value, 'input')
                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="seccion-principal__input">
                                <div className="seccion-principal__input__input">
                                    <Input
                                        type="number"
                                        nombre="horas"
                                        placeholder="Cada cuántas horas 00"
                                        max={48}
                                        value={detalleMedicamento?.horas ?? '00'}
                                        changeFunction={
                                            (value, nombre) => {
                                                handlerOnChangeMedicamento(nombre, value, 'input')
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className="seccion-principal__input">
                                <div className="seccion-principal__input__input">
                                    <Button
                                        size="medium"
                                        texto="Agregar medicamento"
                                        onClick={handlerAgregarMedicamento}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    {MedicamentosLista?.map((medicamento) => {

                        let principal = {
                            nombre: medicamento?.codigo,
                            precio: medicamento?.eureka_st_productos_precios[0] ? medicamento?.eureka_st_productos_precios[0].precio : 0,
                        }
                        return <ListaMedicamentos
                            medicamentoPrincipal={principal}
                            // otrosMedicamentos={[
                            //     { nombre: 'paracetamol' }
                            // ]}
                            permitirEditar={false}
                            onChange={(m, i) => {
                                console.log(m)
                                console.log(i)
                            }}
                        />
                    })}
                </Formulario>
            </>
        )
    }
}

export default FormReceta