import { Card, Button, Input } from 'eureka-design'
import { useEffect, useState } from 'react'
import { MESES } from '../../js/util'
import DetalleRecetaHeader from '../../components/Receta/DetalleRecetaHeader/DetalleRecetaHeader'

const styleDisplayFlexSpace = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const styleDisplayFlex = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '18px'
}

const DetallesReceta = ({ titulo, receta, UpTittle }) => {


    useEffect(() => {
        UpTittle(titulo)
    })


    return (
        <>
            <DetalleRecetaHeader receta={receta} />
        </>
    )
}

export default DetallesReceta