import { useState } from 'react'
import nouser from '../../imgs/nouser.jpg'
import iconoEmpresa from '../../imgs/icono.svg'
import { capitalize, obtenerLogo } from '../../js/util'
import { Theme, Navegador } from 'eureka-design'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { config } from '../../config/config'
import { obtenerRol, ROL_ADMINISTRADOR, ROL_DOCTOR } from '../../js/rol'
import ListaPacientes from '../pacientes/lista-pacientes'
import Perfil from '../perfil/Perfil'
import ListaRecetas from '../recetas/lista-recetas'
import FormReceta from '../recetas/form-receta'
// import Inicio from '../inicio/inicio'
import Documentacion from '../perfil/Documentacion'
import DetallesReceta from '../recetas/detalles-receta'
import ListaDoctores from '../doctores/lista-doctores'
import ListaMedicinas from '../medicinas/lista-medicinas'
import ListaFarmacias from '../farmacias/lista-farmacias'
import FormDoctor from '../doctores/form-doctor'
import Ganancia from '../perfil/Ganancia'
import InfoDoctor from '../doctores/info-doctor'

const Index = () => {
    const [usuario] = useState(infoUsuario())
    const [rol] = useState(obtenerRol(usuario))
    let opciones = {}

    if (rol === ROL_ADMINISTRADOR.nombre) {
        opciones = {
            'Doctores': {
                opcion: {
                    texto: 'Doctores',
                    icono: 'fa-user-md'
                },
                pantallas: {
                    lista_doctores: {
                        props: {},
                        titulo: 'Doctores',
                        componente: ListaDoctores,
                    },
                    form_doctor: {
                        props: {},
                        titulo: 'Doctor',
                        componente: FormDoctor,
                    },
                    info_doctor: {
                        props: {},
                        titulo: 'Información doctor',
                        componente: InfoDoctor,
                    },
                    // form_receta: {
                    //     props: {},
                    //     titulo: 'Receta',
                    //     componente: FormReceta,
                    // },
                }
            },

            "Pacientes": {
                opcion: {
                    texto: 'Pacientes',
                    icono: 'fa-users'
                },
                pantallas: {
                    lista_pacientes: {
                        props: {},
                        titulo: 'Pacientes',
                        componente: ListaPacientes,
                    },
                }
            },
            'Medicinas': {
                opcion: {
                    texto: 'Medicinas',
                    icono: 'fa-cube'
                },
                pantallas: {
                    lista_medicinas: {
                        props: {},
                        titulo: 'Medicinas',
                        componente: ListaMedicinas,
                    },
                }
            },
        }
    }

    else {
        opciones = {
        }
    }

    opciones = {
        // 'Inicio': {
        //     opcion: {
        //         texto: 'Inicio',
        //         icono: 'fa-house'
        //     },
        //     pantallas: {
        //         inicio: {
        //             props: {},
        //             titulo: 'Inicio',
        //             componente: Inicio,
        //         },
        //     }
        // },
        ...opciones,
        'Recetas': {

            opcion: {
                texto: 'Recetas',
                icono: 'fa-file-lines'
            },
            pantallas: {
                lista_recetas: {
                    props: {},
                    titulo: 'Recetas',
                    componente: ListaRecetas,
                },
                form_receta: {
                    props: {},
                    titulo: 'Receta',
                    componente: FormReceta,
                },
                detalles_receta: {
                    props: {},
                    titulo: 'Receta',
                    componente: DetallesReceta,
                }
            }
        },
        'Perfil': {
            pantallas: {
                perfil: {
                    props: { rol },
                    titulo: 'Mi perfil',
                    componente: Perfil,
                },
                documentacion: {
                    props: {},
                    titulo: 'Mi documentación',
                    componente: Documentacion,
                },
                ganancia: {
                    props: {},
                    titulo: 'Mis ganancias',
                    componente: Ganancia,
                },
            }
        }
    }

    return (
        <Theme
            darkColor={config.colorSecundario}
            lightColor={config.colorPrimario}
            compareDark={config.colorPrimario}
        >
            <Navegador
                themeDefault="light"
                empresa={{
                    nombre: "Mega Price Farma",
                    imagen: iconoEmpresa,
                    version: "v1.0.0-2411181 demo",
                }}
                usuario={{
                    nombre: usuario?.nombre ?? 'Sin nombre',
                    rol: capitalize(rol),
                    imagen: obtenerLogo(usuario?.eureka_cr_multimedia, 'logo', nouser),
                    pantalla: "Perfil"
                }}
                componentes={opciones}
            ></ Navegador>
        </Theme>
    )
}

export default Index