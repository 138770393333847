import { Card, Button, Input } from 'eureka-design'
import { useEffect, useState } from 'react'
import { MESES, numberFormat } from '../../../js/util'
import CardProducto from '../../CardProducto/CardProducto'
import "./DetalleRecetaHeader.css"

const styleDisplayFlex = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '18px'
}


export default function DetalleRecetaHeader({ receta }) {

    const [Receta, setReceta] = useState(receta)

    useEffect(() => {
        let _receta = receta;
        if (receta) {

            setReceta(receta)
        }
    }, [receta])


    return <>
        <div className='receta-detalle'>
            <Card>
                <div className='receta-detalle__top'>
                    <div className='receta-detalle__top-header'>
                        <div style={styleDisplayFlex}>
                            <p><b>Fecha:</b> {Receta.fecha ? `${Receta.fecha.getDate()} ${MESES[Receta.fecha.getMonth()]} ${Receta.fecha.getFullYear()}` : 'Fecha no válida'}</p>
                            <p><b>Paciente:</b> {Receta.cliente?.nombre ?? 'Sin paciente'}</p>
                        </div>
                        <div style={styleDisplayFlex}>
                            {/* <Button
                                iconLeft="fa-print"
                                texto="Imprimir"
                            /> */}
                            {/* <Button
                                iconLeft="fa-file-arrow-down"
                                texto="Descargar"
                            /> */}
                        </div>
                    </div>

                    <Input
                        type="textarea"
                        placeholder="Diagnóstico"
                        value={Receta.detalle}
                        disabled={true}
                    />
                </div>
            </Card>
            <Card>
                <div className='receta-detalle-body'>
                    <div className='receta-detalle-body__header'>
                        <h3>{Receta.conceptos.length} Medicamentos recetados</h3>
                        <h2><b>TOTAL: </b>{numberFormat(Receta.total)}</h2>
                    </div>
                    {
                        Receta.conceptos?.map((concepto, index) => {
                            return (<>
                                <p>{concepto.codigo} - Tomar {concepto.periodo?.cantidad} cada {concepto.periodo?.hora} horas por {concepto.periodo?.total} {concepto.periodo?.nombre}</p>
                                <br />
                                <div className='receta-detalle__productos-row'>
                                    <div className='receta-detalle__productos-row__head'>
                                        <span>Producto recetado</span>
                                        <span>Productos similares</span>
                                    </div>
                                    <div className='receta-detalle__productos-row__body'>
                                        <CardProducto producto={{
                                            nombre: 'Paracetamol',
                                            inventario: concepto.inventario,
                                            precio: concepto.precio,
                                        }} />
                                        <div className='receta-detalle__productos-separador'></div>
                                        {
                                            concepto.similares?.map((similar, index) => {
                                                return <CardProducto
                                                    modoNormal={false}
                                                    key={index}
                                                    producto={{
                                                        nombre: similar.nombre,
                                                        inventario: similar.inventario,
                                                        precio: similar.precio,
                                                    }} />
                                            })
                                            // <CardProducto
                                            //     modoNormal={false}
                                            //     producto={{
                                            //         nombre: 'Paracetamol',
                                            //         eureka_st_productos_precios: [{
                                            //             precio: 150,
                                            //             numero: 10
                                            //         }]
                                            //     }} />
                                        }
                                    </div>
                                </div>
                            </>
                            )
                        })
                    }


                </div>

            </Card>
        </div>

    </>
}