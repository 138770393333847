import { useState, useEffect } from 'react'
import { ModalForm, DropFile, Alerta } from 'eureka-design'
import TablaProductos from '../../components/TablaProductos/TablaProductos'
import { useObtenerCategoriasQuery, useObtenerMedicinasQuery } from '../../services/medicina'
import InfoMedicina from './info-medicina'


const ListaMedicinas = ({ ShowAlert, Push }) => {
    const [productos_filtrados, fijaProductosFiltrados] = useState([])
    const [categorias_filtrados, fijaCategoriasFiltrados] = useState([])
    const [niveles, fijaNiveles] = useState([])
    const [sincronización, set_sincronizacion] = useState({
        paso: "-",
        mensaje: '',
        estatus: false
    })
    const [Producto, fijaProducto] = useState({})
    const [show_modaldetalles, cambiarEstadoModalDetalles] = useState(false)

    const { data: categorias, isLoading: cargandoCategorias, refetch: refetchCategorias } = useObtenerCategoriasQuery()
    const { data: productos, isLoading: cargandoProductos, refetch: refetchProductos } = useObtenerMedicinasQuery({ niveles: niveles.length > 0 ? niveles : null })

    useEffect(() => {
        if (productos) {
            let productosFiltrados = productos.map((producto) => {
                let precio = producto.eureka_st_productos_precios?.find((precio) => precio.numero === 1)?.precio ?? 0
                let inventario = producto.eureka_st_productos_almacenes[0]?.cantidad ?? 0

                return {
                    id: producto.id,
                    foto: producto.foto ?? null,
                    codigo: producto.codigo,
                    costo: producto.costo,
                    precio: precio,
                    inventario: inventario,
                    principios: producto.eureka_st_productos_caracteristicas,
                    data: producto
                }
            })
            fijaProductosFiltrados(productosFiltrados)
        }
    }, [productos])

    useEffect(() => {
        if (categorias) {
            let categoriasFiltradas = categorias.map((categoria) => {
                return {
                    id: categoria.id,
                    texto: categoria.nombre,
                    subcategorias: categoria.rutas.map((subcategoria) => {
                        return {
                            id: subcategoria.eureka_st_nivele_hijo.id,
                            texto: subcategoria.eureka_st_nivele_hijo.nombre
                        }
                    })
                }
            })

            fijaCategoriasFiltrados(categoriasFiltradas)
        }
    }, [categorias])

    const handlerAgregar = () => {
        Push('form_producto', {
            producto: null
        })
    }

    const handlerEditar = (item) => {
        Push('form_producto', {
            producto: item,
            titulo: `${item?.nombre}`
        })
    }

    const handlerSincronizar = () => {
        const eventSource = new EventSource('http://localhost:5000/get/sincronizar');
        set_sincronizacion(old => ({ ...old, estatus: true }));

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            set_sincronizacion({
                paso: data.paso,
                mensaje: data.mensaje,
                estatus: true
            });
            if (data.paso === 8 || data.paso === 8) {
                setTimeout(() => {
                    set_sincronizacion({
                        paso: "-",
                        mensaje: "",
                        estatus: false
                    });
                }, 3000);
                refetchProductos()
                refetchCategorias()
                eventSource.close();
            }
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            set_sincronizacion({
                paso: "-",
                mensaje: "Ha ocurrido un error con el servidor",
                estatus: true
            });
            setTimeout(() => {
                set_sincronizacion({
                    paso: "-",
                    mensaje: "",
                    estatus: false
                });
            }, 3000);
            eventSource.close();
        };
    }


    const handlerBuscar = () => {

    }

    return (
        <>
            {/* <ModalForm
                show={mostrandoModalActualizar}
                titulo="Actualizar inventario"
                onClose={() => {
                    verModalActualizar(false)
                }}
                botones={[
                    { texto: 'Actualizar inventario', onClick: handlerActualizarInventario }
                ]}
            >
                <p><b>Sube el archivo con tus productos para poder actualizar tu inventario de manera masiva</b></p>
                <br />
                <DropFile />
            </ModalForm> */}

            {/* <ModalCargando
                mostrar={mostrandoModalCargando}
                modo="normal"
                titulo="Actualizando inventario"
                texto="Espera un momento..."
            /> */}
            <InfoMedicina
                mostrar={show_modaldetalles}
                onClose={() => { cambiarEstadoModalDetalles(false) }}
                producto={Producto}
            />
            {sincronización.estatus ? <Alerta
                doClose={false}
                icono="loader"
                titulo="Sincronizando productos"
                mensaje={`Esta operación puede tardar varios minutos ...\n\n Paso ${sincronización.paso} - ${sincronización.mensaje}`}
            /> : undefined}
            <TablaProductos
                data={productos_filtrados}
                categorias={categorias_filtrados}
                buquedad={{
                    nombre: 'id',
                    icono: 'fa-magnifying-glass',
                    opciones: [],
                    value: null,
                    placeholder: 'Buscar producto...',
                    onChage: handlerBuscar
                }}
                botonesHeader={[
                    { texto: 'Sincronizar Productos', icono: 'fa-rotate', onClick: handlerSincronizar },
                    // { texto: 'Sincronizar Imágenes', icono: 'fa-rotate', onClick: () => { } },
                    // { tooltip: 'Sincronizar', icono: 'fa-rotate', onClick: handlerSincronizar },
                ]}
                onClickCard={(item) => {
                    fijaProducto(item.data)
                    cambiarEstadoModalDetalles(true)
                }}
                onClickCategoria={(c) => {
                    let niveles = [];
                    c.forEach((categoria) => {
                        if (categoria.estatus)
                            niveles.push(categoria.value.id)
                    })

                    fijaNiveles(niveles)
                }}
            />
        </>
    )
}

export default ListaMedicinas