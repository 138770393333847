import { Tabla, buildData, Miniatura } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useObtenerRecetasQuery } from '../../services/receta'
import { numberFormat, obtenerLogo } from '../../js/util'

const ListaRecetas = ({ Push }) => {
    const { data, isLoading } = useObtenerRecetasQuery()

    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalArchivados, fijaTotalArchivados] = useState(0)
    const [Recetas, setRecetas] = useState([])

    useEffect(() => {
        const calcularTotal = (pacientes = [], estatus = 'activo') => {
            return pacientes.filter((paciente) => paciente?.estatus === estatus).length
        }
        if (data) {
            let _recetas = []
            _recetas = data.map((receta) => {
                let conceptos = receta.eureka_st_transacciones_conceptos ?? [];

                conceptos = conceptos.map((concepto) => {
                    let hora = concepto.eureka_st_transacciones_periodo?.hora.slice(0, 2);

                    hora = hora.startsWith("0") ? hora.slice(1) : hora
                    let precio = 0;
                    let inventario = 0;
                    let similares = [];
                    concepto.eureka_st_producto?.eureka_st_productos_precios.forEach((p) => {
                        if (p.precio > precio) {
                            precio = p.precio
                        }
                    })
                    concepto.eureka_st_producto?.eureka_st_productos_almacenes.forEach((a) => {
                        inventario += a.cantidad
                    })
                    concepto.eureka_st_producto?.eureka_productos_similares.forEach((s) => {
                        if (s.id === concepto.eureka_st_producto.id) return
                        similares.push({
                            id: s.id,
                            codigo: s.codigo,
                            nombre: s.codigo,
                            inventario: s.eureka_st_productos_almacenes.reduce((acc, a) => acc + a.cantidad, 0),
                            precio: s.eureka_st_productos_precios[0].precio,
                        })
                    })
                    return {
                        id: concepto.id,
                        costo: concepto.costo,
                        nombre: concepto.eureka_st_producto?.nombre,
                        codigo: concepto.eureka_st_producto?.codigo,
                        descripcion: concepto.eureka_st_producto?.descripcion,
                        categoria: concepto.eureka_st_producto?.eureka_st_nivele?.nombre,
                        cantidad: concepto.cantidad,
                        precio: precio,
                        inventario: inventario,
                        similares: similares,
                        periodo: {
                            id: concepto.eureka_st_transacciones_periodo?.id,
                            cantidad: concepto.eureka_st_transacciones_periodo?.cantidad,
                            hora: hora,
                            nombre: concepto.eureka_st_transacciones_periodo?.nombre,
                            total: concepto.eureka_st_transacciones_periodo?.total,
                        }
                    }
                })
                return {

                    id: receta.id,
                    estatus: receta.estatus,
                    total: receta.total,
                    cliente: receta.eureka_bs_cliente ?? {},
                    vendedor: receta.eureka_ac_vendedor ?? {},
                    conceptos: conceptos ?? [],
                    fecha: new Date(receta.fecha),
                    detalle: receta.eureka_st_transacciones_detalles[0] ? receta.eureka_st_transacciones_detalles[0].texto : "Sin diagnóstico",
                }
            })

            setRecetas(_recetas)
        }

        fijaTotalActivos(calcularTotal(data, 'activo'))
        fijaTotalArchivados(calcularTotal(data, 'inactivo'))

    }, [data])

    const handlerRegistrar = () => {
        Push('form_receta', {
            titulo: 'Nueva receta',
        })
    }

    const handlerEditar = (item) => {

    }

    const handlerArchivar = (item) => {

    }

    const handlerVerDetalles = (item) => {
        Push('detalles_receta', {
            titulo: `Receta ${item.item.id}`,
            receta: item.item
        })
    }

    if (isLoading) return
    else {
        return (
            <>
                <Tabla
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay recetas registradas"
                    busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                    keys={[
                        {
                            icono: 'fa-users',
                            text: "Todos",
                            detalle: totalActivos + totalArchivados,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Activos",
                            detalle: totalActivos,
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Archivados",
                            detalle: totalArchivados,
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    headers={[
                        { key: 'fecha', text: 'Fecha', type: 'date' },

                        { key: 'cliente', text: 'Nombre', type: 'text' },
                        { key: 'total', text: 'Total receta', type: 'precio' },
                        { key: 'ganancia', text: 'Ganancia', type: 'precio' },
                        { key: 'medicamentos', text: 'Medicamentos', type: 'text' },
                    ]}
                    data={buildData(Recetas, [
                        { key: 'fecha', type: 'date' },

                        {
                            key: 'cliente',
                            type: 'text',
                            value: (item) => item?.cliente?.nombre,
                            text: (item) => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <Miniatura src={obtenerLogo(item?.cliente?.eureka_cr_multimedia, 'foto')} avatar={true} />
                                        <span>{item?.cliente?.nombre}</span>
                                    </div>
                                )
                            }
                        },
                        { key: 'total', type: 'precio' },
                        {
                            key: 'ganancia',
                            type: 'precio',
                            text: (item) => {
                                return numberFormat(20)
                            }
                        },
                        {
                            key: 'medicamentos',
                            type: 'text',
                            text: (item) => `${item.conceptos.length} medicamentos`,
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-pen', tooltip: 'Editar', onClick: handlerEditar },
                        { icono: 'fa-trash-can', tooltip: 'Archivar', onClick: handlerArchivar },
                    ]}
                    onClickRow={handlerVerDetalles}
                />
            </>
        )
    }
}

export default ListaRecetas